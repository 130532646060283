
import { defineComponent } from 'vue';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import {
  Actions as PLogActions,
  Getters as PLogGetters,
} from '@/store/enums/PaymentLogEnums';
import { Getters as SubscriptionGetters } from '@/store/enums/SubscriptionEnums';
import DataTable from '@/components/datatables/KTDataTable.vue';

import { Subscription } from '@/models/SubscriptionModel';
import { IHeaderConfiguration } from '@/models/TableModel';

const subscriptionDetailsInit = {} as unknown as Subscription;

export default defineComponent({
  name: 'subscription-details-page',
  data: () => ({
    hasSubscription: false,
    loadingDetails: false,
    subscriptionDetails: subscriptionDetailsInit,
    table: {
      total: 0,
      loading: false,
      items: [],
      search: '',
    },
  }),
  components: {
    DataTable,
  },
  async mounted() {
    this.loadInit();
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      actionErrors: SubscriptionGetters.GET_ACTION_ERROR,
      subscription: SubscriptionGetters.GET_SUBSCRIPTION,
      paylogs: PLogGetters.GET_PAYMENT_LOGS,
      pagination: PLogGetters.GET_PAGINATION,
    }),

    tableHeaders() {
      return [
        { name: 'Date', key: 'created_at', sortable: true, align: 'center' },
        {
          name: 'Transaction',
          key: 'transaction',
          sortable: true,
          align: 'left',
        },
        { name: 'Remarks', key: 'remarks', sortable: true, align: 'left' },
        { name: 'Quantity', key: 'quantity', sortable: true, align: 'center' },
        { name: 'Amount', key: 'amount_total', sortable: true, align: 'right' },
        { name: 'Tax', key: 'amount_tax', sortable: true, align: 'right' },
      ] as IHeaderConfiguration[];
    },
  },
  methods: {
    ...mapActions({
      fetchPaylogs: PLogActions.FETCH_PAYMENT_LOGS,
    }),
    formatList() {
      return this.paylogs.map((item) => {
        return {
          id: item.id,
          type: item.type,
          amount: item.amount,
          transaction: item.action,
          quantity: item.metadata.quantity,
          currency: item.currency,
          amount_total: item.amount_total,
          amount_tax: item.amount_tax,
          remarks: item.remarks,
          created_at: item.created_at,
        };
      });
    },
    formatDate(date, format = 'MMM DD, hh:mm A') {
      return moment(new Date(date * 1000)).format(format);
    },
    moneyFormat(amount) {
      return `$ ${(amount / 100).toFixed(2)}`;
    },
    statusTag() {
      return this.subscription?.status;
    },
    async loadInit() {
      this.handleFetchLogs();
    },
    async handleFetchLogs() {
      if (this.loadingDetails) return;
      this.loadingDetails = true;

      return await this.fetchPaylogs()
        .then(async () => {
          this.table.items = await this.formatList();
          this.table.total = await this.pagination.total;
          this.loadingDetails = false;
        })
        .catch(() => {
          this.loadingDetails = false;
        })
        .finally(() => {
          this.loadingDetails = false;
        });
    },
  },
});
