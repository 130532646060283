
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import {
  Actions as SubscriptionActions,
  Getters as SubscriptionGetters,
} from '@/store/enums/SubscriptionEnums';

import { Getters as AuthGetter } from '@/store/enums/AuthEnums';
import Navigator from '@/views/new-design/layout/Navigator.vue';
import Subscription from '@/views/new-design/pages/Settings/Subscriptions/Subscription.vue';
import Invoices from '@/views/new-design/pages/Settings/Subscriptions/Invoices.vue';
import Transactions from '@/views/new-design/pages/Settings/Subscriptions/Transactions.vue';

export default defineComponent({
  name: 'subscription-details',
  components: {
    Navigator,
    Subscription,
    Invoices,
    Transactions,
  },
  data() {
    return {
      currentTab: 'subscriptions',
    };
  },
  computed: {
    ...mapGetters({
      subscription: SubscriptionGetters.GET_SUBSCRIPTION,
      authUser: AuthGetter.GET_AUTH_USER,
    }),
  },
  mounted() {
    this.loadInit();
  },
  methods: {
    ...mapActions({
      fetchCurrentSubscription: SubscriptionActions.FETCH_CURRENT_SUBSCRIPTION,
    }),
    async loadInit() {
      await this.fetchCurrentSubscription();
    },
    setTab(tab: string) {
      this.currentTab = tab;
    },
  },
});
